exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-demandascomunicacao-campanhas-js": () => import("./../../../src/pages/demandascomunicacao/campanhas.js" /* webpackChunkName: "component---src-pages-demandascomunicacao-campanhas-js" */),
  "component---src-pages-demandascomunicacao-identidade-visual-js": () => import("./../../../src/pages/demandascomunicacao/identidade-visual.js" /* webpackChunkName: "component---src-pages-demandascomunicacao-identidade-visual-js" */),
  "component---src-pages-demandascomunicacao-index-js": () => import("./../../../src/pages/demandascomunicacao/index.js" /* webpackChunkName: "component---src-pages-demandascomunicacao-index-js" */),
  "component---src-pages-demandascomunicacao-outros-js": () => import("./../../../src/pages/demandascomunicacao/outros.js" /* webpackChunkName: "component---src-pages-demandascomunicacao-outros-js" */),
  "component---src-pages-demandascomunicacao-redes-sociais-js": () => import("./../../../src/pages/demandascomunicacao/redes-sociais.js" /* webpackChunkName: "component---src-pages-demandascomunicacao-redes-sociais-js" */),
  "component---src-pages-demandascomunicacao-sites-js": () => import("./../../../src/pages/demandascomunicacao/sites.js" /* webpackChunkName: "component---src-pages-demandascomunicacao-sites-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pagamento-js": () => import("./../../../src/pages/pagamento.js" /* webpackChunkName: "component---src-pages-pagamento-js" */),
  "component---src-pages-sucesso-js": () => import("./../../../src/pages/sucesso.js" /* webpackChunkName: "component---src-pages-sucesso-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

